// @ts-ignore
import { Controller } from '@hotwired/stimulus';
// @ts-ignore
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap';

// Connects to data-controller='edit-sso-settings'
export default class extends Controller {
  static targets = ['table'];

  // @ts-ignore
  selectorValue: string;

  isReachEnd: boolean | undefined;

  isLoading: boolean | undefined;

  currentPage: number | undefined;

  /* eslirclass-methods-use-this: 0 */
  connect() {
    this.isReachEnd = false;
    this.isLoading = false;
    this.currentPage = 0;

    jQuery(window).scroll(() => {
      if (this.isReachEnd) { return; }

      if (this.isLoading) { return; }

      // @ts-ignore
      if (jQuery(window).scrollTop() + jQuery(window).height() < jQuery(document).height()) {
        return;
      }

      this.isLoading = true;

      // @ts-ignore
      const params = (new URL(document.location)).searchParams;
      const subjectId = params.get('subject_id');

      const data : {
        page: number | undefined,
        subject_id: string | undefined
      } = { page: 2, subject_id: undefined };

      if (this.currentPage) { data.page = this.currentPage + 1; }

      if (subjectId) { data.subject_id = subjectId; }

      jQuery.ajax({
        type: 'GET',
        url: '/problems/load_more',
        data,
        // @ts-ignore
        success: (result) => {
          jQuery('#index-problems-tbody').append(result);
          this.currentPage = data.page;
        },
        error: () => {
          this.isReachEnd = true;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    });
  }

  /* eslint class-methods-use-this: 0 */
  disconnect() {}
}
