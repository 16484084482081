import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="change-select"
export default class extends Controller {
  // @ts-ignore
  declare classesValue;

  schoolYearSelect = document.getElementById('school_year') as HTMLSelectElement;

  static values = {
    classes: Object,
  };

  static targets = ['trigger-select', 'option-selects'];

  connect() {
    this.triggerSelectTarget.addEventListener('change', this.displayChange);
    this.schoolYearSelect.addEventListener('change', this.classListChange);
  }

  disconnect() {
    this.triggerSelectTarget.removeEventListener('change', this.displayChange);
    this.schoolYearSelect.removeEventListener('change', this.classListChange);
  }

  displayChange = () => {
    const schoolYearSelect = document.getElementById('school_year') as HTMLSelectElement;
    const schoolClassSelect = document.getElementById('school_class') as HTMLSelectElement;
    const triggerValue = this.triggerSelectTarget.value;

    this.optionSelectsTargets.forEach((e: HTMLSelectElement) => {
      if (triggerValue === 'school_class' || e.id === triggerValue) {
        e.disabled = false;
        e.classList.remove('d-none');
        Array.from(schoolClassSelect.options).forEach((event) => {
          schoolClassSelect.removeChild(event);
        });
        schoolClassSelect.options[schoolClassSelect.options.length] = new Option('クラス');
        schoolClassSelect.options[0].style.display = 'none';
        schoolClassSelect.style.color = '#ced4da';
        schoolYearSelect.options[0].selected = true;
      } else {
        e.disabled = true;
        e.classList.add('d-none');
      }
    });
  };

  classListChange = () => {
    const schoolClassSelect = document.getElementById('school_class') as HTMLSelectElement;
    const classListArray =
      this.classesValue[this.schoolYearSelect.value] as Array<{ id: string, name: string }>;

    try {
      schoolClassSelect.style.color = '#495057';
      Array.from(schoolClassSelect.options).forEach((e) => {
        schoolClassSelect.removeChild(e);
      });
      classListArray.forEach((e) => {
        schoolClassSelect.options[schoolClassSelect.options.length] = new Option(`${e.name}組`, e.id);
      });
    } catch {
      schoolClassSelect.options[schoolClassSelect.options.length] = new Option('クラス');
      schoolClassSelect.options[0].style.display = 'none';
      schoolClassSelect.style.color = '#ced4da';
    }
  };

  get triggerSelectTarget() {
    return this.targets.find('trigger-select') as HTMLSelectElement;
  }

  get optionSelectsTargets() {
    return this.targets.findAll('option-selects') as Array<HTMLSelectElement>;
  }
}
