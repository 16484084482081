import React, { useState, useEffect } from 'react';
import PasswordStrengthBar, {
  PasswordStrengthBarProps,
} from 'react-password-strength-bar';

interface PasswordStrengthMeterProps extends Partial<PasswordStrengthBarProps> {
  target?: string;
}

export const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  target = '',
  ...props
}) => {
  const [password, setPassword] = useState('');
  const strengthScore = document.getElementById('strength-score')! as HTMLInputElement;

  useEffect(() => {
    if (!target) {
      return () => {};
    }

    const targetElement = document.querySelector(target);
    if (!targetElement) {
      return () => {};
    }

    const handleChange = (e: Event) => {
      setPassword((e.target as HTMLInputElement).value);
    };

    targetElement.addEventListener('input', handleChange);

    return () => {
      targetElement.removeEventListener('input', handleChange);
    };
  }, []);

  return (
    <PasswordStrengthBar
      password={password}
      scoreWords={['とても弱い', '弱い', '普通', '強い', 'とても強い']}
      minLength={8}
      shortScoreWord="8文字以上"
      onChangeScore={(score: number) => {
        strengthScore.value = `${score}`;
      }}
      {...props}
    />
  );
};
