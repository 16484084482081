import React from 'react';
import ReactDOM from 'react-dom';
import { Controller } from '@hotwired/stimulus';

import { SurveyPreview } from '@/components/organisms/SurveyPreview';

export default class extends Controller {
  // @ts-ignore
  declare schemaValue;

  // @ts-ignore
  declare uiSchemaValue;

  // @ts-ignore
  declare answerValue;

  static values = {
    schema: Object,
    uiSchema: Object,
    answer: Object,
  };

  static targets = ['schema', 'uiSchema', 'answer', 'canvas'];

  connect() {
    ReactDOM.render(
      <SurveyPreview
        schema={this.schemaValue}
        uiSchema={this.uiSchemaValue}
        answer={this.answerPresence(this.answerValue)}
      />,
      this.canvas,
    );
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.canvas);
  }

  answerPresence = (obj: object) => {
    if (Object.keys(obj).length === 0) return undefined;

    return obj;
  };

  get canvas() {
    return this.targets.find('canvas') as HTMLElement;
  }
}
