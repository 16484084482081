import React, { useState, useEffect } from 'react';
import {
  Input,
  FormFeedback,
} from 'reactstrap';
import * as yup from 'yup';
import { FBCheckbox } from '../checkbox/FBCheckbox';
import { Tooltip } from '../Tooltip';
import {
  getRandomId,
  fetchLabel,
} from '../utils';
import type {
  Parameters,
  FormInput,
  Mods,
} from '../types';
import { PlaceholderInput } from '../inputs/PlaceholderInput';

type CardLongAnswerParameterInputsProps = {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
};

// specify the inputs required for a string type object
export const CardLongAnswerParameterInputs: React.FC<CardLongAnswerParameterInputsProps> = ({
  parameters,
  onChange,
}) => {
  const [elementId] = useState(getRandomId());

  return (
    <div>
      <h4>Minimum Length</h4>
      <Input
        value={parameters.minLength ? parameters.minLength : ''}
        key="minLength"
        type="number"
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...parameters, minLength: parseInt(ev.target.value, 10) });
        }}
        className="card-modal-number"
      />
      <h4>Maximum Length</h4>
      <Input
        value={parameters.maxLength ? parameters.maxLength : ''}
        key="maxLength"
        type="number"
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...parameters, maxLength: parseInt(ev.target.value, 10) });
        }}
        className="card-modal-number"
      />
      <h4>
        Regular Expression Pattern{' '}
        <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions">
          <Tooltip
            id={`${elementId}_regex`}
            type="help"
            text="Regular expression pattern that this must satisfy"
          />
        </a>
      </h4>
      <Input
        value={parameters.pattern ? parameters.pattern : ''}
        key="pattern"
        type="text"
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...parameters, pattern: ev.target.value });
        }}
        className="card-modal-text"
      />
      <PlaceholderInput parameters={parameters} onChange={onChange} />
      <div className="card-modal-boolean">
        <FBCheckbox
          onChangeValue={() => {
            onChange({
              ...parameters,
              'ui:autofocus': parameters['ui:autofocus']
                ? parameters['ui:autofocus'] !== true
                : true,
            });
          }}
          isChecked={
            parameters['ui:autofocus']
              ? parameters['ui:autofocus'] === true
              : false
          }
          label="Auto Focus"
        />
      </div>
    </div>
  );
};

/* eslint-disable no-template-curly-in-string */
const validate = async ({
  parameters,
  mods,
}: {
  parameters: Parameters,
  mods?: Mods,
}) => {
  yup.setLocale({
    number: {
      max: fetchLabel('validations.longAnswer.max', 'must be less than or equal to ${max}', mods),
    },
  });

  const longAnswerParameterSchema = yup.object().shape({
    maxLength: yup.number().integer().min(0).nullable(),
    rows: yup.number().integer().min(0).nullable(),
  });

  return longAnswerParameterSchema.validate(parameters);
};

type LongAnswerProps = {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
  mods: Mods;
};

const LongAnswer = ({
  parameters,
  onChange,
  mods,
}: LongAnswerProps) => {
  const [errors, setErrors] = useState<Record<string, any>>({});
  const maximumLengthLabel = fetchLabel('maximumLengthLabel', 'Maximum Length', mods);
  const rowsLabel = fetchLabel('rowsLabel', 'Rows', mods);

  useEffect(() => {
    validate({ parameters, mods }).then(() => {
      setErrors({});
    }).catch((err) => {
      setErrors({ [err.path]: err.errors });
    });
  }, [parameters]);

  return (
    <div className="card-entry-row">
      <div className="card-entry">
        <h5>{maximumLengthLabel}</h5>
        <Input
          value={parameters.maxLength ? parameters.maxLength : ''}
          key="maxLength"
          type="number"
          min="1"
          max="1500"
          data-action="survey--validation#validateNumber"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...parameters, maxLength: parseInt(ev.target.value, 10) });
          }}
          className="card-modal-number number-valid-field"
          invalid={!!errors.maxLength}
        />
        <FormFeedback valid={!errors.maxLength}>
          {errors.maxLength}
        </FormFeedback>
      </div>
      <div className="card-entry">
        <h5>{rowsLabel}</h5>
        <Input
          value={parameters['ui:options']?.rows ? parameters['ui:options'].rows : ''}
          key="rows"
          type="number"
          min="1"
          max="25"
          data-action="survey--validation#validateNumber"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ ...parameters, 'ui:options': { rows: parseInt(ev.target.value, 10) } });
          }}
          className="card-modal-number number-valid-field"
          invalid={!!errors.rows}
        />
        <FormFeedback valid={!errors.rows}>
          {errors.rows}
        </FormFeedback>
      </div>
    </div>
  );
};

export const longAnswerInputs: Record<string, FormInput> = {
  longAnswer: {
    displayName: 'Long Answer',
    matchIf: [
      {
        types: ['string'],
        widget: 'textarea',
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {
      'ui:widget': 'textarea',
    },
    type: 'string',
    cardBody: LongAnswer,
    modalBody: CardLongAnswerParameterInputs,
    possibleOptions: ['rows'],
  },
};
