import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='survey--validation"
export default class extends Controller {
  static targets = ['question-form', 'observeMinMax'];

  // 回答形式「チェックボックス」の場合、他の要素の変化によってmin, maxが変わることがあるため、min, maxが変化するたびvalidationを発動
  observeMinMaxTargetConnected(target: Element) {
    const numberValidFields = target.querySelectorAll<HTMLInputElement>('.number-valid-field');
    numberValidFields.forEach((el) => {
      const observer = new MutationObserver(() => {
        this.validateNumber(el);
      });
      observer.observe(el, {
        attributes: true,
        attributeFilter: ['min', 'max'],
      });
    });
  }

  submitValidate(e: Event) {
    e.preventDefault();
    const requiredFields = document.querySelectorAll<HTMLInputElement>('.required-field');
    requiredFields.forEach((el) => this.validateString(el));
    const numberValidFields = document.querySelectorAll<HTMLInputElement>('.number-valid-field');
    numberValidFields.forEach((el) => this.validateNumber(el));
    const invalidElements = document.querySelectorAll<HTMLDivElement>('.required-invalid, .number-invalid');
    if (invalidElements.length === 0) {
      this.questionFormTarget.submit();
    } else {
      this.moveUpToErrorPlace(invalidElements[0]);
    }
  }

  validateString = (target: HTMLInputElement | Event) => {
    const targetInput = target instanceof Event ? target.target as HTMLInputElement : target;
    const parentDivElement = targetInput.parentElement as HTMLDivElement;
    const targetMaxLength = targetInput.maxLength;
    if (targetInput.value.length > targetMaxLength) {
      parentDivElement.setAttribute('over-char-limit-message', `${targetMaxLength}文字以下で入力してください`);
      parentDivElement.classList.add('character-limit-invalid');
    } else {
      parentDivElement.removeAttribute('over-char-limit-message');
      parentDivElement.classList.remove('character-limit-invalid');
    }
    if (targetInput.value === '') {
      parentDivElement.classList.add('required-invalid');
    } else {
      parentDivElement.classList.remove('required-invalid');
    }
  };

  validateNumber = (target: HTMLInputElement | Event) => {
    const targetInput = target instanceof Event ? target.target as HTMLInputElement : target;
    const parentDivElement = targetInput.parentElement as HTMLDivElement;
    const inputNumber = Number(targetInput.value);
    const maxNumber = Number(targetInput.max);
    const minNumber = Number(targetInput.min);

    if (inputNumber < minNumber || inputNumber > maxNumber) {
      const message = inputNumber < minNumber ? `${minNumber}以上で入力してください` : `${maxNumber}以下で入力してください`;
      parentDivElement.setAttribute('invalid-num-message', message);
      parentDivElement.classList.add('number-invalid');
    } else {
      parentDivElement.removeAttribute('invalid-num-message');
      parentDivElement.classList.remove('number-invalid');
    }
  };

  deleteRequiredMsg = (e: Event) => {
    const target = e.currentTarget as SVGElement;
    target.parentElement!.previousElementSibling!.classList.remove('required-invalid');
  };

  moveUpToErrorPlace = (el: HTMLDivElement) => {
    const cardContainer = el.closest('.card-container') as HTMLDivElement;
    const closeArrow = cardContainer.querySelector('.fa-caret-right') as SVGElement;

    if (closeArrow) {
      const questionLabel = cardContainer.querySelector('.label') as HTMLSpanElement;
      questionLabel.click();
    }
    el.parentElement!.scrollIntoView();
  };

  get questionFormTarget() {
    return this.targets.find('question-form') as HTMLFormElement;
  }
}
