import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';

function initializeSelect2(root: any) {
  root.find('.js-select2').select2({
    placeholder: '- 選択してください -',
    allowClear: true,
    language: {
      noResults: () => '見つかりません',
    },
  });
}

function destroySelect2(root: any) {
  root.find('.js-select2').select2('destroy');
}

document.addEventListener('turbolinks:before-cache', () => {
  destroySelect2($(document));
});

document.addEventListener('turbolinks:load', () => {
  initializeSelect2($(document));
});
