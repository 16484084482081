import React from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';

// eslint-disable-next-line no-unused-vars
import 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// Plugins
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autolink';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from 'raw-loader!tinymce/skins/content/default/content.css';
import contentUiCss from 'raw-loader!tinymce/skins/ui/oxide/content.css';

// Languages
import language from 'tinymce-i18n/langs5/ja';

interface WysiwygEditorProps extends Partial<IAllProps> {
  imagesUploadUrl: string;
  blobUrlTemplate: string;
  images_upload_handler: UploadHandler;
}

export const WysiwygEditor: React.FC<WysiwygEditorProps> = ({
  init,
  images_upload_handler,
  ...props
}) => (
  <Editor
    {...props}
    init={{
      min_height: 500,
      relative_urls: false,
      remove_script_host: false,
      convert_urls: true,
      skin: false,
      content_css: false,
      content_style: [contentCss, contentUiCss].join('\n'),
      plugins: 'autolink lists image',
      toolbar: 'h1 h2 h3 underline italic bold forecolor backcolor bullist numlist image',
      language: 'ja',
      language_url: language,
      images_upload_handler,
      branding: false,
      statusbar: false,
      menubar: false,
      ...init,
    }}
  />
);
