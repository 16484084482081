// @ts-ignore
import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='selected-notice-role'
export default class extends Controller {
  static targets = ['role', 'selected'];

  static values = {
    role: String,
  };

  roleMap: {
    'true,true,true': string;
    'true,false,false': string;
    'false,true,false': string;
    'false,false,true': string;
    'true,true,false': string;
    'true,false,true': string;
    'false,true,true': string;
    'false,false,false': string;
  } | undefined;

  /* eslint-disable no-param-reassign */
  connect() {
    this.roleMap = {
      'true,true,true': '1',
      'true,false,false': '2',
      'false,true,false': '3',
      'false,false,true': '4',
      'true,true,false': '5',
      'true,false,true': '6',
      'false,true,true': '7',
      'false,false,false': '0',
    };

    const swappedRoleMap = {};
    Object.keys(this.roleMap).forEach((key) => {
      // @ts-ignore
      if (Object.hasOwn(this.roleMap, key)) {
        // @ts-ignore
        swappedRoleMap[this.roleMap[key]] = key;
      }
    });

    // @ts-ignore
    const initRoleValues = swappedRoleMap[this.role.value].split(',');
    // @ts-ignore
    Object.entries(this.selectedTargets).forEach(([key, selectedTarget]) => {
      // eslint-disable-line no-param-reassign
      (selectedTarget as HTMLInputElement).checked = JSON.parse(initRoleValues[key]);
    });
  }

  get role() {
    // @ts-ignore
    return this.targets.find('role') as HTMLInputElement;
  }

  updateRole() {
    // @ts-ignore
    const selectedMap = this.selectedTargets.map((selectedTarget) => selectedTarget.checked);
    // @ts-ignore
    this.roleTarget.value = this.roleMap[selectedMap.toString()];
  }
}
