import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="disabled-select"
export default class extends Controller {
  static targets = ['option-select', 'option-selects', 'trigger-radio'];

  // @ts-ignore
  declare triggerRadioValue;

  // @ts-ignore
  declare checkedValue;

  // @ts-ignore
  declare uncheckedValue;

  static values = {
    triggerRadio: String,
    checked: Boolean,
    unchecked: Boolean,
  };

  connect() {
    this.triggerRadioTargets.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', this.radioChange);
    });
  }

  disconnect() {
    this.triggerRadioTargets.forEach((element: HTMLInputElement) => {
      element.removeEventListener('change', this.radioChange);
    });
  }

  radioChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const value = target.value === this.triggerRadioValue ? this.checkedValue : this.uncheckedValue;
    this.optionSelectsTarget.disabled = value;
    this.optionSelectsTarget.value = 'all_students';
    this.optionSelectsTargets.forEach((e: HTMLSelectElement) => {
      e.classList.add('d-none');
      e.disabled = true;
    });
  };

  get triggerRadioTargets() {
    return this.targets.findAll('trigger-radio') as Array<HTMLInputElement>;
  }

  get optionSelectsTarget() {
    return this.targets.find('option-select') as HTMLSelectElement;
  }

  get optionSelectsTargets() {
    return this.targets.findAll('option-selects') as Array<HTMLSelectElement>;
  }
}
