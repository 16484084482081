// @ts-ignore
import { Controller } from '@hotwired/stimulus';
// @ts-ignore
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap';

// Connects to data-controller="edit-sso-settings"
export default class extends Controller {
  static targets = ['form', 'ssoEnabled'];

  // @ts-ignore
  selectorValue: string;

  static values = {};

  /* eslint class-methods-use-this: 0 */
  connect() {}

  /* eslint class-methods-use-this: 0 */
  disconnect() {}

  submit() {
    // @ts-ignore
    this.formTarget.submit();
  }

  confirmDialog(event: Event) {
    event.preventDefault();

    // メモ：
    // this.ssoEnabledTarget.checked (True: 使用しない, False: 使用する)

    // CASE:「使用しない」を検索する
    // @ts-ignore
    if (this.ssoEnabledTarget.checked) {
      this.showSsoConfirmDialog();
      return;
    }

    // CASE:「使用する」を選択する
    jQuery.ajax({
      dataType: 'json',
      url: '/sso_settings/check_login_name',
      // @ts-ignore
      success: (data) => {
        if (data.exist_login_name_is_not_email) {
          this.showSsoWarningDialog();
          return;
        }

        this.showSsoConfirmDialog();
      },
    });
  }

  showSsoWarningDialog() {
    // @ts-ignore
    jQuery('#ssoWarningDialog').modal('show');
  }

  showSsoConfirmDialog() {
    // @ts-ignore
    jQuery('#ssoConfirmDialog').modal('show');
  }
}
