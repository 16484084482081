// @ts-ignore
import { Controller } from '@hotwired/stimulus';
// @ts-ignore
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap';

export default class extends Controller {
  static targets = [];

  static values = {};

  /* eslint class-methods-use-this: 0 */
  connect() {}

  /* eslint class-methods-use-this: 0 */
  disconnect() {}

  onChangeCourse(event: { target: { value: string } }) {
    fetch(`/problems/course_chapters?${new URLSearchParams(`course_id=${event.target.value}`)}`)
      .then((response) => response.json())
      .then((data) => {
        jQuery('#problem_course_chapter_id').empty();
        for (let i = 0; i < data.course_chapters.length; i += 1) {
          jQuery('#problem_course_chapter_id').append(
            `<option value="${data.course_chapters[i].id}">${data.course_chapters[i].name}</option>`,
          );
        }

        jQuery('#problem_course_section_id').empty();
        for (let i = 0; i < data.course_sections.length; i += 1) {
          jQuery('#problem_course_section_id').append(
            `<option value="${data.course_sections[i].id}">${data.course_sections[i].name}</option>`,
          );
        }
      });
  }

  onChangeCourseChapter(event: { target: { value: string } }) {
    fetch(`/problems/course_sections?${new URLSearchParams(`course_chapter_id=${event.target.value}`)}`)
      .then((response) => response.json())
      .then((data) => {
        jQuery('#problem_course_section_id').empty();
        for (let i = 0; i < data.course_sections.length; i += 1) {
          jQuery('#problem_course_section_id').append(
            `<option value="${data.course_sections[i].id}">${data.course_sections[i].name}</option>`,
          );
        }
      });
  }
}
