import React from 'react';
import { useSchoolAdministratorsQuery } from '@/generated/graphql';

export const SchoolAdministratorsSample = () => {
  const { loading, error, data } = useSchoolAdministratorsQuery();

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error ({error.message})</p>;
  }

  return (
    <div>
      {data?.schoolAdministrators.map(({ id, name }, index) => (
        <div key={id}>
          <p>
            {index}: {name}
          </p>
        </div>
      ))}
    </div>
  );
};
