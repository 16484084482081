import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="disabled-button"
export default class extends Controller {
  static targets = ['option-check', 'trigger-radio'];

  // @ts-ignore
  declare triggerRadioValue;

  // @ts-ignore
  declare checkedValue;

  // @ts-ignore
  declare uncheckedValue;

  static values = {
    triggerRadio: String,
    checked: Boolean,
    unchecked: Boolean,
  };

  connect() {
    this.triggerRadioTargets.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', this.radioChange);
    });
  }

  disconnect() {
    this.triggerRadioTargets.forEach((element: HTMLInputElement) => {
      element.removeEventListener('change', this.radioChange);
    });
  }

  radioChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const value = target.value.includes('selected') ? this.checkedValue : this.uncheckedValue;

    this.schoolAddbuttonTarget.disabled = value;
    this.optioncheckTargets.forEach((checkboxes) => {
      /* eslint no-param-reassign: 0 */
      checkboxes.disabled = value;
      checkboxes.checked = !value;
      /* eslint no-param-reassign: 0 */
    });
  };

  get triggerRadioTargets() {
    return this.targets.findAll('trigger-radio') as Array<HTMLInputElement>;
  }

  get schoolAddbuttonTarget() {
    return this.targets.find('school-add-button') as HTMLInputElement;
  }

  get optioncheckTargets() {
    return this.targets.findAll('option-check') as HTMLInputElement[];
  }
}
