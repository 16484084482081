import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

/** A school_administrator */
export type SchoolAdministrator = {
  __typename?: 'SchoolAdministrator';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isOwner: Scalars['Boolean'];
  loginName: Scalars['String'];
  name: Scalars['String'];
  nameEn: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Update school_administrator sample */
  updateSchoolAdministratorSample?: Maybe<UpdateSchoolAdministratorSamplePayload>;
};

export type MutationUpdateSchoolAdministratorSampleArgs = {
  input: UpdateSchoolAdministratorSampleInput;
};

export type MutationError = {
  __typename?: 'MutationError';
  messages: Array<Scalars['String']>;
  path?: Maybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetch school_administrators */
  schoolAdministrators: Array<SchoolAdministrator>;
};

export type QuerySchoolAdministratorsArgs = {
  onlyOwner?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateSchoolAdministratorSample */
export type UpdateSchoolAdministratorSampleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  loginName: Scalars['String'];
};

/** Autogenerated return type of UpdateSchoolAdministratorSample */
export type UpdateSchoolAdministratorSamplePayload = {
  __typename?: 'UpdateSchoolAdministratorSamplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  schoolAdministrator?: Maybe<SchoolAdministrator>;
  errors?: Maybe<Array<MutationError>>;
};

export type SchoolAdministratorsQueryVariables = Exact<{ [key: string]: never }>;

export type SchoolAdministratorsQuery = { __typename?: 'Query' } & {
  schoolAdministrators: Array<
    { __typename?: 'SchoolAdministrator' } & Pick<SchoolAdministrator, 'id' | 'name'>
  >;
};

export const SchoolAdministratorsDocument = gql`
  query schoolAdministrators {
    schoolAdministrators {
      id
      name
    }
  }
`;

/**
 * __useSchoolAdministratorsQuery__
 *
 * To run a query within a React component, call `useSchoolAdministratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAdministratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAdministratorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolAdministratorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchoolAdministratorsQuery,
    SchoolAdministratorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolAdministratorsQuery, SchoolAdministratorsQueryVariables>(
    SchoolAdministratorsDocument,
    options,
  );
}
export function useSchoolAdministratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolAdministratorsQuery,
    SchoolAdministratorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolAdministratorsQuery, SchoolAdministratorsQueryVariables>(
    SchoolAdministratorsDocument,
    options,
  );
}
export type SchoolAdministratorsQueryHookResult = ReturnType<
  typeof useSchoolAdministratorsQuery
>;
export type SchoolAdministratorsLazyQueryHookResult = ReturnType<
  typeof useSchoolAdministratorsLazyQuery
>;
export type SchoolAdministratorsQueryResult = Apollo.QueryResult<
  SchoolAdministratorsQuery,
  SchoolAdministratorsQueryVariables
>;
