import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['dependent', 'option'];

  connect() {
    this.optionTarget.addEventListener('click', this.styleChange);
  }

  disconnect() {
    this.optionTarget.removeEventListener('click', this.styleChange);
  }

  styleChange = (event: Event) => {
    event.preventDefault();
    this.dependentTarget.style.display = 'none';
  };

  get dependentTarget() {
    return this.targets.find('dependent') as HTMLInputElement;
  }

  get optionTarget() {
    return this.targets.find('option') as HTMLInputElement;
  }
}
