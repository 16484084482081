import ShowIcon from '@/images/icons/show.svg';
import HideIcon from '@/images/icons/hide.svg';

document.addEventListener('turbolinks:load', () => {
  const passwordInputs = document.querySelectorAll<HTMLInputElement>('.password_mask');
  if (passwordInputs.length === 0) {
    return;
  }

  const eyeButtonStyles: string =
    'cursor: pointer;' +
    'position: absolute;' +
    'top: 12px;' +
    'right: 16px;' +
    'width: 16px';

  passwordInputs.forEach((passwordInput) => {
    const newButton: HTMLImageElement = document.createElement('img');
    newButton.src = HideIcon;
    newButton.classList.add('eye_button');
    newButton.setAttribute('style', eyeButtonStyles);
    passwordInput.after(newButton);
  });

  const eyeButtons = document.querySelectorAll<HTMLImageElement>('.eye_button');
  eyeButtons.forEach((eyeButton) => {
    eyeButton.addEventListener('click', () => {
      const dupEyeButton = eyeButton;
      const passwordInput = eyeButton.previousElementSibling as HTMLInputElement;
      passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
      dupEyeButton.src = passwordInput.type === 'password' ? HideIcon : ShowIcon;
    });
  });
});
