import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="clipboard"
export default class extends Controller {
  // @ts-ignore

  static targets = ['source', 'copy_button'];

  connect() {
    this.copyButtonTarget.addEventListener('click', this.copy.bind(this));
  }

  disconnect() {
    this.copyButtonTarget.removeEventListener('change', this.copy.bind(this));
  }

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.placeholder);
  }

  get sourceTarget() {
    return this.targets.find('source') as HTMLInputElement;
  }

  get copyButtonTarget() {
    return this.targets.find('copy_button') as HTMLInputElement;
  }
}
