import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="collapse"
export default class extends Controller {
  // @ts-ignore
  declare triggerValue;

  static values = {
    trigger: String,
  };

  static targets = ['options', 'item'];

  connect() {
    this.optionsTargets.forEach((element: HTMLInputElement) => {
      element.addEventListener('change', this.displayChange);
    });
    this.itemTarget.addEventListener('change', this.cardChange);
  }

  disconnect() {
    this.optionsTargets.forEach((element: HTMLInputElement) => {
      element.removeEventListener('change', this.displayChange);
    });
    this.itemTarget.removeEventListener('change', this.cardChange);
  }

  displayChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const ssoProviderSelect = document.getElementById('school_sso_provider')! as HTMLSelectElement;
    const azureEditForm = document.getElementById('azure_edit_form')! as HTMLInputElement;
    const googleEditForm = document.getElementById('google_edit_form')! as HTMLInputElement;
    if (target.value === this.triggerValue) {
      azureEditForm.style.display = 'none';
      googleEditForm.style.display = 'none';
    } else {
      azureEditForm.style.display = 'block';
      ssoProviderSelect.options[0].selected = true;
    }
  };

  cardChange = () => {
    const ssoProviderSelect = document.getElementById('school_sso_provider')! as HTMLSelectElement;
    const azureEditForm = document.getElementById('azure_edit_form')! as HTMLInputElement;
    const googleEditForm = document.getElementById('google_edit_form')! as HTMLInputElement;
    if (ssoProviderSelect.options[0].selected === true) {
      azureEditForm.style.display = 'block';
      googleEditForm.style.display = 'none';
    } else {
      azureEditForm.style.display = 'none';
      googleEditForm.style.display = 'block';
    }
  };

  get optionsTargets() {
    return this.targets.findAll('options') as Array<HTMLInputElement>;
  }

  get itemTarget() {
    return this.targets.find('item') as HTMLInputElement;
  }
}
