import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { SchoolAdministratorsSample } from '@/components/organisms/SchoolAdministratorsSample';

const token =
  document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ||
  '';

const client = new ApolloClient({
  uri: '/graphql',
  headers: {
    'X-CSRF-Token': token,
  },
  cache: new InMemoryCache(),
});

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('js-school-admins-sample');
  if (!element) {
    return;
  }

  ReactDOM.render(
    <ApolloProvider client={client}>
      <SchoolAdministratorsSample />
    </ApolloProvider>,
    element,
  );
});
