// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'admin-lte';
import 'bootstrap/dist/js/bootstrap';

import '@/controllers';
import '@/modules/password_strength_meter';
import '@/modules/wysiwyg_editor';
import '@/modules/school_administrators_sample';
import '@/modules/select2';
import '@/modules/password_toggler';
import '@/modules/password_mask';

import '@/css/application.scss';

Rails.start();
Turbolinks.start();
ActiveStorage.start();
