import React from 'react';
import Form from '@rjsf/core';

type Props = {
  schema: any;
  uiSchema: any;
  answer?: any;
};

export const SurveyPreview = ({
  schema,
  uiSchema,
  answer = undefined,
}: Props) => (
  <Form
    schema={schema}
    uiSchema={uiSchema}
    formData={answer}
  >
    <button type="button" className="btn btn-primary d-none">
      登録
    </button>
  </Form>
);
